/* 숫자 3자리 마다 콤마 찍기 */
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/* 첫글자를 대문자로 */
export const capitalize = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : null;
};

/* string 글자수 줄이기 */
export const strcut = (str, limit) => {
  return str ? (str.length < limit ? str : str.slice(0, limit) + "...") : str;
};
