import Link from "next/link";
import classNames from "classnames";

const PageItem = ({ isActive, children, url }) => {
  return (
    <Link href={url}>
      <div
        className={classNames(
          "bg-stone-100 w-6 h-6 rounded-full flex-center text-sm",
          isActive && "font-bold bg-indigo-100 text-indigo-500"
        )}
      >
        <span>{children}</span>
      </div>
    </Link>
  );
};

const Pagination = ({ baseUrl, page = 1, total = 1, perPage = 5 }) => {
  const last = Math.ceil(total / perPage);
  return (
    <div className="flex-center space-x-4 py-8">
      {[...Array(last)].map((_, i) => (
        <PageItem
          isActive={page === i + 1 ? true : false}
          url={`${baseUrl}/${i + 1}`}
          key={`page-item-${i + 1}`}
        >
          {i + 1}
        </PageItem>
      ))}
    </div>
  );
};

export default Pagination;
