import Link from "next/link";
import useApp from "@/hooks/useApp";
import Container from "@/components/ui/Container";

const Logo = () => {
  const { siteIcon, siteName } = useApp();
  return (
    <Link href="/">
      <div className="flex-left space-x-2 cursor-pointer">
        <div className="flex-center h-[24px] w-[24px] rounded bg-black">
          <span className=" text-white font-bold text-sm">{siteIcon}</span>
        </div>
        <div className="font-bold text-xl">{siteName}</div>
      </div>
    </Link>
  );
};

const Header = () => {
  return (
    <div className="border-b border-black">
      <Container>
        <div className="h-[60px] flex-between">
          <Logo />
        </div>
      </Container>
    </div>
  );
};

const Footer = () => {
  const { copyright } = useApp();

  return (
    <div className="border-t border-black py-4 lg:py-8">
      <Container>
        <div className="flex-between text-sm text-black">
          <div className="">{copyright}</div>
          <div className="flex-left space-x-8 ">
            <Link href="/terms">
              <div className="hover:underline">Terms</div>
            </Link>
            <Link href="/privacy">
              <div className="hover:underline">Privacy</div>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

const Layout = ({ children }) => {
  return (
    <div className="w-full min-h-screen flex flex-col">
      <Header />
      <div className="flex-grow">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
