import Layout from "@/components/layout/Layout";
// import Posts from "@/components/blog/Posts";
import PostList from "@/components/blog/PostList";

const BlogPosts = ({ ...rest }) => {
  return (
    <Layout>
      <PostList {...rest} />
    </Layout>
  );
};
export default BlogPosts;
