import BlogPosts from "@/containers/blog/BlogPosts";
import { getPosts } from "@/services/blog";

const perPage = 6;

export default function Home({ posts }) {
  const page = 1;
  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;

  return (
    <BlogPosts
      page={page}
      total={posts.length}
      perPage={perPage}
      posts={posts.slice(startIndex, endIndex)}
    />
  );
}

export const getStaticProps = async () => {
  const posts = await getPosts();
  return { props: { posts } };
};
