import PostCard from "@/components/blog/PostCard";
import Container from "@/components/ui/Container";
import Pagination from "@/components/ui/Pagination";

const PostList = ({ tag, page = 1, total, perPage, title, posts }) => {
  return (
    <Container>
      <div className="py-8 lg:py-16">
        {title && (
          <div className="text-center font-bold text-xl lg:text-3xl mb-8 lg:mb-16">
            {title}
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-4 lg:gap-y-8 md:gap-8">
          {posts &&
            posts.map((post) => (
              <PostCard post={post} key={`relatedPost-${post.id}`} />
            ))}
        </div>
        <Pagination
          baseUrl={tag ? `/tags/${tag}` : "/p"}
          page={page}
          total={total}
          perPage={perPage}
        />
      </div>
    </Container>
  );
};
export default PostList;
