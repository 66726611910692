import moment from "moment";
import "moment/locale/ko";
import { strcut } from "@/lib/utils2";
import { useRouter } from "next/router";
import Image from "next/image";

const PostCard = ({ post }) => {
  const { id, title, desc, wdate, thumbData, tag } = post || {};
  const { url, extension, alt, width, height } = thumbData || {};

  const router = useRouter();
  const handleClick = () => {
    router.push(`/${id}`);
  };

  return (
    <div
      onClick={handleClick}
      className="border border-black rounded-lg overflow-hidden cursor-pointer flex lg:block h-[100px] lg:h-full"
    >
      <div className="w-[100px] h-[100px] lg:h-[220px] overflow-hidden lg:w-full shrink-0 ">
        {thumbData ? (
          <div className="flex-center w-[150px] h-[100px] lg:w-full lg:h-[220px] overflow-hidden shrink-0">
            <Image
              src={`${url}.${extension}`}
              alt={alt}
              quality={80}
              width={width}
              height={height}
            />
          </div>
        ) : (
          <div className=" w-[100px] h-[100px] lg:w-full lg:h-[220px] flex-center bg-indigo-500 ">
            <span className="text-white  font-bold text-base lg:text-3xl">
              #{tag}
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-2 px-4 py-3 ">
        <div className="font-bold text-base lg:text-lg">
          {strcut(title, 70)}
        </div>
        <div className="text-stone-500 text-sm flex-grow hidden lg:block">
          {strcut(desc, 50)}
        </div>
        <div className="text-stone-500 text-xs border-t pt-2 hidden lg:block">
          {moment(wdate).format("YYYY년 MM월 DD일")}
        </div>
      </div>
    </div>
  );
};

export default PostCard;
